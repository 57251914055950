import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';

import spacings from '../../theme/spacings';

export const Container = styled.View`
  flex: 1;
  background-color: #fff;
`;

export const BoxShadow = styled.View`
shadow-color: #a3b1c5;
shadow-offset: 5px 5px;
shadow-opacity: 0.5;
`

export const FlatList = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingVertical: spacings.SMALL,
  },
})`
  background-color: #fff;
`;

export const StyledEmptyListContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.Image`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const StyledEmptyList = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyListView = styled.View`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyListText = styled.Text`
  font-size: 16px;
  color: #000;
  font-family: ${useFontFamily(500)};
`;

export const UpSide = styled.View`
  width: 60%;
  justify-content: center;
  align-items: center;
  margin : 0 auto;
`;

export const ImgIcon = styled.Image`
  width: 70vw;
  height : 200px;
  background-color : #eee;
  border-top-left-radius : 10px;
  border-top-right-radius : 10px;
  border : 0;
`;

export const CardContainer = styled.TouchableOpacity`
  width: 75%;
  height : 350px;
  flex-direction: columns;
  justify-content: space-between;
  margin-bottom: 3px
  margin : 0 auto;
  margin-bottom : 10%;
  shadow-color: #a3b1c5;
	shadow-offset: 5px 5px;
	shadow-opacity: 0.5;
  border-radius : 10px;
  border : 1px #eee;
  padding : 6%;
  padding-top : 0;
`;

export const NoticeContainer = styled.TouchableOpacity`
  width: 75%;
  height : 160px;
  flex-direction: columns;
  justify-content: space-between;
  margin-bottom: 3px
  margin : 0 auto;
  margin-bottom : 10%;
  shadow-color: #a3b1c5;
	shadow-offset: 5px 5px;
	shadow-opacity: 0.5;
  border-radius : 10px;
  border : 1px #eee;
  padding : 6%;

`;

export const NoticeTitle = styled.Text`
  font-size: 14px;
  height : 80px;
  text-align: left;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  overflow : hidden;
  text-overflow: ellipsis;
  font-family: ${useFontFamily(400)};
`;

export const Title = styled.Text`
  font-size: 14px;
  height : 80px;
  text-align: left;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  overflow : hidden;
  text-overflow: ellipsis;
  margin-top : 5%;
  font-family: ${useFontFamily(400)};
`;

export const NoticeContent = styled.Text`
  font-size: 14px;
  height : 150px;
  text-align: left;
  font-weight: 400;
  display: inline-block;
  overflow : hidden;
  text-overflow: ellipsis;
  font-family: ${useFontFamily(400)};
`;


export const Content = styled.Text`
  font-size: 14px;
  height : 180px;
  text-align: left;
  font-weight: 400;
	margin-top: 5%;
  display: inline-block;
  overflow : hidden;
  text-overflow: ellipsis;
  font-family: ${useFontFamily(400)};
`;

export const DateText = styled.Text`
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  color : #aaa;
  margin-left : 10%;
  font-family: ${useFontFamily(300)};
`;

export const ReadMore = styled.Text`
  font-size: 14px;
  text-align: right;
  color : blue;
  margin-right : 4%;
  margin-bottom : 3%;
`;

