import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';
import spacings from '../../theme/spacings';
import { StyleSheet } from 'react-native';
import { white } from 'react-native-paper/lib/typescript/styles/themes/v2/colors';

export const Container = styled.View`
  flex: 1;
  background-color: #fff;
`;

export const ContainerHeader = styled.View`
  width: 100%;
  height: 260px;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const ContainerBody = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
  justify-content: center;
  align-items: center;
  margin-top: -25px;
`;

export const AvailableBalanceTitle = styled.Text`
  font-size: 16px;
  color: #f3f3f3;
  text-align: center;
  font-family: ${useFontFamily(400)};
`;

export const ContainerVerticalButtons = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  top: 12px;
`;

export const ContainerVerticalTextButtons = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  color : white;
`;

export const Button = styled.TouchableOpacity`
  width: 80vw;
  border-radius : 10px
  padding: 18px;
  background-color: rgba(135, 206, 250, 0.5);
  justify-content: center;
  align-items: center;
`;

export const ButtonTitle = styled.Text`
  width: 100%;
  font-size: 14px;
  color: #f3f3f3;
  font-family: ${useFontFamily(500)};
  text-align: center;
  margin-top: 5px;
`;

export const FlatList = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingVertical: spacings.SMALL,
  },
})`
  background-color: #fff;
`;

export const StyledEmptyListContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.Image`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const StyledEmptyList = styled.View`
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyListView = styled.View`
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
`;

export const StyledEmptyListText = styled.Text`
  font-size: 16px;
  color: #000;
  font-family: ${useFontFamily(500)};
`;

export const LeftSide = styled.View`
  width: 70px;
  justify-content: center;
  align-items: center;
`;

export const RightSide = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #cdcdcd;
`;

export const ViewIcon = styled.View`
  width: 45px;
  height: 45px;
  border-radius : 45px;
  justify-content: center;
  align-items: center;
`;

export const ImgIcon = styled.Image`
  width: 50px;
  height: 50px;
`;

export const CardContainer = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
`;

export const Name = styled.Text`
  width: 40%;
  font-size: 14px;
  text-align: left;
  font-family: ${useFontFamily(500)};
`;

export const Price = styled.Text`
  width: 60%;
  font-size: 14px;
  text-align: right;
  padding-right: 30px;
  font-family: ${useFontFamily(500)};
`;

export const Percent = styled.Text`
  width: 100px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: green;
  font-family: ${useFontFamily(100)};
`;

export const PriceUsd = styled.Text`
  width: 30%;
  font-size: 14px;
  text-align: left;
  padding-right: 30px;
  color: #808080;
  font-family: ${useFontFamily(500)};
`;

export const RightSideViewHeader = styled.View`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const RightSideViewBottom = styled.View`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const PercentChange24h = styled.Text`
  width: 70%;
  font-size: 14px;
  text-align: left;
  color: green;
  font-family: ${useFontFamily(400)};
`;

export const HalfCardContainer = styled.TouchableOpacity`
  width: 49%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
  float : left;
`;

export const styles = StyleSheet.create({ 

  title: {
    fontSize: 12,
    textAlign : 'left',
    color : '#ddd',
    width : '40%',
    marginTop : 10,
  },
  hr : {
    width: '80vw',
    color : 'white',
  },
  amount :{
    fontSize: 24,
    color: '#fff',
    textAlign: 'right',
    fontFamily: `${useFontFamily(700)}`,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width : '60%',
  },
  symbol : {
    fontSize : 15,
    color : '#ddd',
  }

})