import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { View, Text, Image, TouchableOpacity } from 'react-native';
import * as Animatable from 'react-native-animatable';

import icLogo from '../../assets/images/ico_gbt.png';
import { styles } from './styles';

export default function WelCome() {
  const { navigate } = useNavigation();

  function onHandleNavigate() {
    navigate('SignIn');
  }
  
  function onSignup() {
    navigate('SignUp');
  }
  return (
    <View style={styles.container}>
      <View style={styles.containerLogo}>
        <Animatable.Image  style={styles.imageSize} delay={600} animation="fadeInDown" source={icLogo} />
      </View>

      <Animatable.View delay={600} animation="fadeInUp" style={styles.containerForm}>
        <Text style={styles.title}>
          This Wallet is official GameBit wallet where you can monitory prices of GBIT
        </Text>
        <Text style={styles.subTitle}>Login to start</Text>

        <TouchableOpacity style={styles.button} onPress={onHandleNavigate}>
          <Text style={styles.buttonText}>로그인</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button2} onPress={onSignup}>
          <Text style={styles.buttonText}>회원 가입</Text>
        </TouchableOpacity>
      </Animatable.View>
    </View>
  );
}
