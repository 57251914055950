import React, { useRef, useEffect, useState } from 'react';

import { View, TextInput, Button, KeyboardAvoidingView, 
  ToastAndroid, Platform, Alert, Text, 
  ScrollView, TouchableOpacity} from 'react-native'
import { Avatar } from 'react-native-paper';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { LOGOUT_USER } from '@store/slices/AuthSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import Icon from 'react-native-vector-icons/Entypo';
import { saveUser } from '../../../src/constants/storage';
import newUser from '../../assets/images/newUser.png';
import { styles, Container } from './styles';

type RootStackParamList = {
  Register: undefined;
  Login: undefined;
  Home:  undefined;
  Edit: undefined;
  Order: {id: number, handleType: string} | undefined;
  Waiting: undefined;
  Find: {prevScreen: boolean} |undefined;
  Garage: {id: number} | undefined;
  BottomNav: undefined;
  History: undefined;
  HistoryDetail: {id: number} | undefined;
  Chat: {phone: number} | undefined;
  ChatHistory: undefined;
};

type EditType = StackNavigationProp<RootStackParamList, 'Edit'>

export function Profile() {
  
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  
  const [SName, setName] = React.useState<string>('');
  const [SEmail, setEmail] = React.useState<string>('');
  const [SPhone, setPhone] = React.useState<string>('');
  const [SAddress, setAddress] = React.useState<string>('');
  const [SPassword, setPassword] = React.useState<string>('');
  const [SPasswordValid, setPasswordValid] = React.useState<string>('');
  const [SButton, setButton] = React.useState<boolean>(false);


  const Content:any[] = [];
  let key: string[];

  const placeholder_list = [
    '이름을 입력해주세요.',
    '',
    '핸드폰 번호를 입력해주세요.', 
  ]
  const icon_list = ['user','mail','phone']
  const autocomplete_list = ['name','email','tel']
  const inputType = ['default', 'default', 'phone-pad', 'default', 'default', 'default']
  const maxLength = [30, 40, 13 ]
  const title = ['사용자 이름', '이메일', '핸드폰번호']
  const disable = [false, true, false];
  const values = [SName, SEmail, SPhone];

  const fields = [setName, setEmail, setPhone, setAddress, 
    setPassword, setPasswordValid]
  
  const auth = useSelector((state) => state.auth);    

  async function loadData() {
    if (auth && auth !== '')  {
      setEmail(auth.email);
      setName(auth.userinfo.name);
      setPhone(auth.userinfo.hpnumber);
    }
  }

  useEffect(() => {
    loadData().catch(console.error);
    setButton(SName !== '' && SEmail !== '' && SPhone !== 0 && SAddress !== '' && SPassword !== '' && SPasswordValid !== '')
    
  }, [ SName, SEmail, SPhone, SAddress, SPassword, SPasswordValid ]);

  const handleLogout =() => {
    dispatch(LOGOUT_USER({ navigate:navigate }));
  }

  for(let i = 0; i <= icon_list.length - 1; i++){
    
      Content.push(
        <>
          <View style={styles.flexHorizontal} key={'View' + i}>
            <Icon 
              name={icon_list[i]} 
              size={30} 
              style={styles.icon}
              color="#434647"
              key={'icon' + i}
            />
            <Text style={styles.title}>{title[i]}</Text>
          </View>
          <TextInput 
            autoComplete={autocomplete_list[i]}
            maxLength={maxLength[i]}
            placeholder={placeholder_list[i]}
            autoCapitalize='none'
            disabled={disable[i]}
            value = {values[i]}
            secureTextEntry={false}
            keyboardType={inputType[i]}
            onChangeText={(value) => fields[i](value)}
            key={i}
            style={styles.textInp}
          />
        </>
      )
   
  }

  const navigation = useNavigation<EditType>();

  const checkInput = () => {
    if(SPassword?.length < 8){
      console.log('Password Need at Least 8 Characters!!');
    }else if(SButton == true && SPassword == SPasswordValid){
      console.log('Profile Updated Successfully!!');
      navigation.navigate('Home');
    }else if(SButton == true && SPassword != SPasswordValid){
      console.log('Password did Not Match!!');
    }else{
      console.log('Please Fill All Required Field!!');
    }
  };

  return (
    
    <ScrollView>
      <View style={{ paddingHorizontal:35}}>
        <KeyboardAvoidingView>
          <View style={styles.avatar}>
            <Avatar.Image 
              size={100}
              style={styles.avatar}
              source={ newUser }/>
          </View>
          <View style={styles.flexVertical}>
            {Content}
          </View>

          <TouchableOpacity onPress={() => checkInput()} style={styles.button}>
            <Text style={styles.buttonText}>SUBMIT</Text>
          </TouchableOpacity>

          <TouchableOpacity onPress={handleLogout} style={styles.buttonLogOut}>
            <Text style={styles.buttonText}>LOGOUT</Text>
          </TouchableOpacity>

        </KeyboardAvoidingView>
      </View>
    </ScrollView>
    
    
  );
}
