import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOGOUT_USER,
  LOGIN_CHECK,
  LOGIN_CHECK_SUCCESS
} from '../slices/AuthSlice';

import { loginUrl, RegisterUrl, UserInfoUrl } from '../../constants/constants';
import axios from 'axios';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { saveUser } from '../../constants/storage';
import alertMessage from '../../helpers/functions/alert'

const loginWithEmailPasswordAsync = async (email: string, password: string) => {
  return axios
    .post(loginUrl, { email, password })
    .then((res) => {
      const loginUser = { loaded : false, email : '', token : '', message : '' };
      if (res.data.status) {
        loginUser.loaded = true;
        loginUser.token = res.data.token;
      } else {
        loginUser.loaded = false;
        loginUser.message = res.data.code;
      }
      
      return loginUser;
    })
    .catch((err) => {
      console.log(err);
      const loginUser = { loaded : false, email : '', token : '', message : '' };
      loginUser.loaded = false;
      loginUser.message = 'login_fail';
      return loginUser;
    });
};


function* loginUser({payload}) {
  
  const {email, password} = payload;
  const { navigate } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.loaded) {
      yield call( () => {
        const { setItem } = useAsyncStorage(saveUser);
        setItem(loginUser.token).catch(console.error);
        
      });
      yield put(LOGIN_USER_SUCCESS({ token : loginUser.token }));
      
      const checkUser = yield call(LoginCheckAsync, loginUser.token); //로그인 후 로그인체크
      if (checkUser.loaded) {
      //const item = { email : User.email , token: User.token  };
        yield put(LOGIN_CHECK_SUCCESS({email : checkUser.email, userinfo : checkUser.userinfo, user_id : checkUser.user_id, coinLists : checkUser.coinLists }));
        navigate('Home');
      }
      
    } else {
      alertMessage(loginUser.message)
      yield put(LOGIN_USER_FAIL({ errorMessage: loginUser.message }));
    }

  } catch (error) {
    //console.log(error);
    alertMessage(loginUser.message)
    yield put(LOGIN_USER_FAIL({ errorMessage: error }));
  }

}

const registerWithEmailPasswordAsync = async (email: string, password: string, recommend: string, name : string, hpNumber : string) => {
  const senddata = {email : email, password : password, recommend : recommend, user_name : name, user_hpnumber : hpNumber}
  return axios
    .post(RegisterUrl, senddata)
    .then((res) => {
      const User = { loaded : false, email : '', token : '', message : '' };
      if (res.data.status) {
        User.loaded = true;
        User.email = res.data.email;
        User.token = res.data.token;
      } else {
        User.loaded = false;
        User.message = res.data.code;
      }
      return User;
    })
    .catch((err) => {
      console.log(err);
      const User = { loaded : true, message : '', token : '' };
      User.message = 'REGISTER FAIL';
      return User;
    });
};

function* register({payload}) {
  
  const { navigate } = payload;
  const {email, password, recommend, name, hpNumber} = payload;

  try {
    const User = yield call(registerWithEmailPasswordAsync, email, password, recommend, name, hpNumber);
    if (User.loaded) {
      //const item = { email : User.email , token: User.token  };
      yield put(REGISTER_USER_SUCCESS({ }));
      navigate('SignIn');
    } else {
      alertMessage(User.message)
      yield put(REGISTER_USER_FAIL({ errorMessage: User.message }));
    }
  } catch (error) {
    console.log(error);
    yield put(REGISTER_USER_FAIL({ errorMessage: error }));
  }
}

function* logOutUser({ payload }) {
  
  const { navigate } = payload;
  
  try {
    yield call(() => {
      const { removeItem } = useAsyncStorage(saveUser);
      removeItem().catch(console.error);
      navigate('WelCome');
    });
    
    yield put({type : LOGOUT_USER});
    
  } catch (error) {
    console.log(error);
    
  }
}

const LoginCheckAsync = async (token: string) => {
  const senddata = { token_type : 'GBIT' };
  
  return axios
    .post(UserInfoUrl , senddata, { headers: { Authorization: `Bearer ${token}` } } )
    .then((res) => {
      const User = { loaded : false, user_id :'', email : '', userinfo:{name:'', hpnumber:'' , admin_level : 0} , coinLists:[], message : ''};
      if (res.status) {
        User.loaded = true;
        User.email = res.data.user.email;
        User.userinfo.name = res.data.user.user_name;
        User.userinfo.hpnumber = res.data.user.user_hpnumber;
        User.userinfo.admin_level = res.data.user.admin_level;
        User.user_id = res.data.user.id;
        User.coinLists = res.data.assets
      } else {
        User.loaded = false;
        User.message = res.data.code;
      }
      return User;
    })
    .catch((err) => {
      console.log(err);
      const User = { loaded : false};
      return User;
    });
};

function* login_Check({payload}) {
  const { token } = payload
  const { navigate } = payload;
  try {
    const User = yield call(LoginCheckAsync, token);
    if (User.loaded) {
      //const item = { email : User.email , token: User.token  };
      yield put(LOGIN_CHECK_SUCCESS({email : User.email, userinfo : User.userinfo, user_id : User.user_id, coinLists : User.coinLists }));
      navigate('Home');

    } else {
      const { removeItem } = useAsyncStorage(saveUser);
      removeItem().catch(console.error);
      navigate('WelCome');
      yield put(REGISTER_USER_FAIL({ errorMessage: User.message }));
    }
  } catch (error) {
    const { removeItem } = useAsyncStorage(saveUser);
    removeItem().catch(console.error);
    navigate('WelCome');
    yield put(REGISTER_USER_FAIL({ errorMessage: error }));
  }
}



export default function* watcher() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(REGISTER_USER, register);
  yield takeLatest(LOGOUT_USER, logOutUser);
  yield takeLatest(LOGIN_CHECK, login_Check);
}
