import React, { useState, useEffect } from 'react';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

const { Navigator, Screen } = createNativeStackNavigator();

import WelCome from '../screens/WelCome';
import SignIn from '../screens/SignIn';
import SignUp from '../screens/SignUp';
import { Wallet } from '../screens/Wallet';
import { Board } from '../screens/Board';
import { BottomTab } from './tabs/BottomTab';
import { SwitchToPwr } from '@screens/SwitchToPwr';
import { Details } from '@screens/Detail';
import {ForgotPassword}  from '@screens/ForgotPassword';
import Loading from '@components/Loading';

import { useSelector,useDispatch } from 'react-redux';
import { LOGIN_CHECK } from '@store/slices/AuthSlice';

import { saveUser } from '../../src/constants/storage';
import Spinner from 'react-native-loading-spinner-overlay';
import { useNavigation } from '@react-navigation/native';

export default function Routes() {
  const { getItem } = useAsyncStorage(saveUser);
  const [user, setUser] = useState<string | null>('');
  const [isLoading, setLoading] = useState<boolean | null>(true);

  const dispatch = useDispatch();
  const { navigate } = useNavigation();

  async function loadData()  {
    const response = await getItem();
    setUser(response);
    if (response && response !== '') dispatch(LOGIN_CHECK({token : response, navigate: navigate}));
    setLoading(false);
  }

  useEffect( () => {

    loadData().catch(console.error);
    
  }, []);
  //const initRoute = user && user !== ''?'HomeLogged':'WelCome'
  
  return (
      isLoading ?
      (
      <Loading />
    ) : (
    <Navigator>
      <Screen name="WelCome" component={WelCome} />
      <Screen name="SignIn" component={SignIn} />
      <Screen name="SignUp" component={SignUp} />
      <Screen name="ForgotPassword" component={ForgotPassword} />
      
      <Screen
        name="Home"
        component={BottomTab}
        options={{
          title: '',
          header: () => {},
        }}
      />
      <Screen name="DetailCoin" component={Wallet} />
      <Screen name="Detail" component={Board} />
      <Screen name="SwitchToPwr" component={SwitchToPwr} />
      <Screen name="Details" component={Details} />
    </Navigator>
    )
  );
}
