import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';

import { StyleSheet } from 'react-native';
import { COLORS } from '../../theme/animateColors';

export const styles = StyleSheet.create({
  hyperlinkStyle: {
    fontSize: 16,
      color: '#1a73e8'
  },
  contentStyle: {
    fontSize: 18,
      color: '#111111'
  }
});
export const Container = styled.View`
  flex: 1;
  background-color: #fff;
  padding : 5%;
  padding-left : 10%;
  padding-right : 10%;
  
`;

export const TitleLabel = styled.Text`
  
  font-family: ${useFontFamily(400)};
`;

export const Title = styled.Text`
  margin-top : 5%;
  font-size: 18px;
  font-weight : 600;
  font-family: ${useFontFamily(400)};
`;

export const Content = styled.Text`
  margin-top : 5%;
  font-size: 16px;
  font-family: ${useFontFamily(400)};
`;

export const UpSide = styled.View`
  width: 60%;
  justify-content: center;
  align-items: center;
  margin : 0 auto;
`;

export const TextLabel= styled.Image`
  font-weight: 600;
`;

export const ImgIcon = styled.Image`
  width: 100%;
  height : 200px;
`;

export const DateText = styled.Text`
  font-size: 12px;
  text-align: right;
  font-weight: 400;
  color : #aaa;
  font-family: ${useFontFamily(300)};
`;
