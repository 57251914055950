import React, { useEffect, useState  } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useSelector, useDispatch } from 'react-redux';

import { LOGIN_CHECK } from '@store/slices/AuthSlice';

import {
  Container,
  styles,
} from './styles';

import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  TextInput,
  Alert,

} from 'react-native';
import * as Animatable from 'react-native-animatable';

import axios from 'axios';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { ServerPath } from '../../constants/constants';
import { saveUser } from '../../constants/storage';
import { fAlert } from '../../helpers/functions/alert'

interface ScrollParams {
  layoutMeasurement: {
    height: number;
    width: number;
  };
  contentOffset: {
    x: number;
    y: number;
  };
  contentSize: {
    height: number;
    width: number;
  };
}

export type CoinData = {
  id: string;
  symbol: string;
  name: string;
  rank: number;
  price_usd: number;
  price_btc: number;
  percent_change_24h: number;
};

export function SwitchToPwr() {
  const dispatch = useDispatch();
  const { getItem } = useAsyncStorage(saveUser);
  const auth = useSelector((state) => state.auth);

  const [name, setName] = useState<string>('');
  const [hp, setHp] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [agress, setAgress] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const { navigate } = useNavigation();
  useEffect(() => {
    if (auth) {
        setName(auth.userinfo.name);
        setEmail(auth.email);
        setHp(auth.userinfo.hpnumber);
      }
  }, []);

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: ScrollParams): boolean => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  const handleRequest = () =>{
    async function fetchData() {
      const { getItem } = useAsyncStorage(saveUser);
      const user = await getItem();
      
      if (name ==='' || hp ==='' || email ==='')  {
        fAlert('필요한 정보가 부족합니다.')
        return;
      }
      const senddata ={ user_id : auth.user_id , email:auth.email, name : name, hp: hp}
      axios
        .post(ServerPath+ '/user/switchtopwr' , senddata, { headers: { Authorization: `Bearer ${user}` } } )
        .then((data : any) => {
          if (data.data.status) {
            fAlert('전환에 동의하셨습니다. 홈화면으로 돌아갑니다.');
            navigate('Home');
          } 
          else if (data.data.code === "duplicate"){
            fAlert('이미 동의하셨습니다.');
          } else {
            navigate('Welcome');
          }
        })
        .catch((err) => {
          console.log(err);
        });
      }
    fetchData();
    }
  
  const handleCancel = () =>{
    navigate('Home');

  }
  
  const onAgress = () => {
    if (!isEnd) { fAlert('동의서를 끝까지 읽어야 합니다.'); return; }
    setAgress(true);
  }
  return (
    <Container>
     <View style={styles.container}>
      <Animatable.View animation="fadeInLeft" delay={600} style={styles.containerHeader}>
        <Text style={styles.message}>PWR 전환 동의 화면입니다.</Text>
      </Animatable.View>

      <Animatable.View style={styles.containerForm} animation="fadeInDown">
       <Text style={styles.title}>이름</Text>
        <TextInput
          style={styles.input}
          value={name}
          onChangeText={setName}
        />
        <Text style={styles.title}>전화번호</Text>
        <TextInput
          style={styles.input}
          value={hp}
          onChangeText={setHp}
        />

        <Text style={styles.title}>이메일</Text>
        <TextInput
          style={styles.input}
          value={email}
          onChangeText={setEmail}
        />

        <ScrollView 
        onScroll={({nativeEvent}) => {
          if (isCloseToBottom(nativeEvent)) {
            setIsEnd(true);
          }
        }}
        style={styles.scroll}
        >

 
        <Text style={styles.scrolltext1}>
          GBIT코인과 PWR코인 교환에 따른
        </Text>
        <Text style={styles.scrolltext1}>
          개인정보 수집 ‧ 이용 ‧ 제공 동의서
        </Text>
        <br />


        <Text style={styles.scrolltext}>
        본인은 Gbit코인을 PWR코인으로 교환하는 것에 동의하고, 재단의 PWR코인 지갑을 사용하기 위해서, 먼저 재단측이 [개인정보 보호법] 제15조 및 제17조에 따라 아래의 내용으로 본인의 개인정보를 수집, 이용 및 제공하는데 동의합니다.
        </Text>
        <br />
        <Text style={styles.scrolltext}>
        □ 개인정보의 수집 및 이용에 관한 사항 
        </Text>

        <Text style={styles.scrolltext}>
          - 수집하는 개인정보 항목 : 성명, 전화번호, 이메일 , 로그인 정보 일체.
          </Text>
          <Text style={styles.scrolltext}>
          - 개인정보의 이용 목적 : 수집된 개인정보를 PWR코인 교환을 목적으로, 재단 지갑 생성하는 데에 활용하며, 목적 외의 용도로는 사용하지 않습니다. 
          </Text>
          <Text style={styles.scrolltext}>
  <br />
        □ 개인정보의 보관 및 이용 기간
        </Text>
        <Text style={styles.scrolltext}>
          - 귀하의 개인정보를 다음과 같이 보관하며, 수집, 이용 및 제공목적이 달성된 경우 [개인정보 보호법] 제21조에 따라 처리합니다.   
          </Text>







      </ScrollView>
        <View style={styles.containerText}>
          
            <TouchableOpacity style={agress?styles.btn_agree_ok:styles.btn_agree_not} onPress={onAgress}>
              <Text >동의</Text>
            </TouchableOpacity>

            <TouchableOpacity style={!agress?styles.btn_agree_ok:styles.btn_agree_not} onPress={() =>{setAgress(false)}}>
            <Text>미동의</Text>
            </TouchableOpacity>

        </View>

        <TouchableOpacity onPress={handleRequest} style={agress?styles.button:styles.buttonDisable}>
          <Text style={styles.buttonText}> 저장</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.buttonRegister}  onPress={handleCancel} >
          <Text style={styles.registerText}>취소 </Text>
        </TouchableOpacity>
      </Animatable.View>
    </View>
    </Container>
  );
}
