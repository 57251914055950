import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';
import { StyleSheet } from 'react-native';

export const ContainerHeader = styled.View`
  width: 100%;
  height: 400px
  background-color: white;
  justify-content: center;
  align-items: center;
`;

export const ContainerCard = styled.View`
background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
border-radius: 20px;
width : 95%
padding : 10px;
padding-bottom : 20px;
`;

export const ContainerBody = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_COLOR};
  justify-content: center;
  align-items: center;
  margin-top: -25px;
`;

export const AvailableBalance = styled.Text`
  font-size: 30px;
  color: #fff;
  font-family: ${useFontFamily(700)};
  margin-left : 10px;
  font-weight : 600;
`;

export const AvailableBalanceTitle = styled.Text`
  font-size: 12px;
  color: #f3f3f3;
  font-family: ${useFontFamily(800)};
  margin-left : 10px;
  font-weight : 600;
`;

export const SmallCard = styled.View`
  background-color : white;
  margin-top : 5px;
  border-radius : 10px;
  padding : 5px;
  font-family: ${useFontFamily(800)};
  font-weight : 700;
`;
export const SmallContent = styled.View`

`;

export const ContainerVerticalButtons = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  top: 12px;
`;

export const ContainerVerticalTextButtons = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  
`;

export const RoundButton = styled.TouchableOpacity`

  width: 95%;
  padding: 9px;
  border-radius: 10px;
  background-color: rgba(135, 206, 250, 0.5);
  color: #f3f3f3;
  justify-content: center;
  align-items: center;
  border : 1px white;
  `;

export const ButtonTitle = styled.Text`
  width: 100%;
  font-size: 16px;
  color: #f3f3f3;
  font-family: ${useFontFamily('bold')};
  font-weight : 900;
  text-align: center;
  margin-top: 5px;
`;

export const Blank = styled.Text`
  height: 2%;
`;

export const Title = styled.Text`
  font-size : 18px;
  color: #f3f3f3;
  font-family: ${useFontFamily(800)};
  font-weight : 700;
  margin-left : 10px;
`;
export const Time  = styled.Text`
font-size : 10px;
color: gray;
font-family: ${useFontFamily(400)};
text-align : left;
width : 100%;
margin-left : 10%;
margin-top : 5px;
`;

export const styles = StyleSheet.create({
  title: {
    fontSize : 14,
  },
  smalltitle: {
    textAlign : 'left',
    
  },
  smallcontent: {
    textAlign : 'right',
    fontWeight : "bold",
  },
  smallcontent2: {
    textAlign : 'right',
    fontWeight : "bold",
    color : '#00ABF0',    
  },
  smallplus : {
    color : 'red',
    textAlign : 'right',
  },
  smallminus : {
    color : 'blue',
    textAlign : 'right',
  },
  containerCard :  {
    borderRadius: 20,
    width : '95%',
    padding : '10px',
    paddingBottom : '20px',
  }

});