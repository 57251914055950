import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,

} from 'react-native';

import * as Animatable from 'react-native-animatable';
import axios from 'axios';

import { styles } from './styles';
import { useAuthContext } from '@contexts/AuthProvider';
import { fAlert } from '../../helpers/functions/alert'
import { ServerPath } from '../../constants/constants';

export type AuthProps = {
  email: string;
  status: boolean;
  logged: boolean;
};

type Props = {
  signUp: (email: string, password: string, recommend_code: string ) => void;
  user: AuthProps;
};

export function ForgotPassword() {
  
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repassword, setrePassword]= useState<string>('');
  const [code, setCode] = useState<string>('');
  const { navigate } = useNavigation();

  function handleOnChangePassword() {
    if (code === '') {
      fAlert('코드가 입력되지 않았습니다.')
      return;
    }
    if (password=== '') {
      fAlert('암호를 입력해주세요.')
      return;
    }

    if (password !== repassword) {
      fAlert('암호가 서로 다릅니다.')
      return;
    }

    axios
    .post(ServerPath + "/auth/verify", { email : email, password: password, token : code, type : 'password' })
    .then((data) => {
      if (data.data.status) {
        navigate('SignIn');
      } else if (data.data.code ==='token') {
        fAlert('코드가 잘못되었습니다.');
      } else {
        fAlert('알 수 없는 문제가 발생했습니다. 다시 시도해주세요.');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

const getCode = () =>{
  if (email ==='') {
    fAlert('이메일을 입력하세요');
    return;
  } 

  axios
    .post(ServerPath + "/auth/send", { email : email })
    .then((data) => {
      if (data.data.status) {
        fAlert('코드가 메일로 발송되었습니다.')
      }  else if (data.data.code ==='invalid') {
        fAlert('해당하는 이메일이 없습니다.');
      } else {
        fAlert('알 수 없는 문제가 발생했습니다. 다시 시도해주세요.');
      }
    })
    .catch((err) => {
      console.log(err);
    });
    
}
  return (
    <View style={styles.container}>
      <Animatable.View animation="fadeInLeft" delay={600} style={styles.containerHeader}>
        <Text style={styles.message}>Welcome</Text>
      </Animatable.View>

      <Animatable.View style={styles.containerForm} animation="fadeInDown">

        <Text style={styles.title}>아이디(이메일)</Text>
        <TextInput 
          style={styles.input}
          placeholder="아이디" 
          value={email} 
          onChangeText={setEmail}
          placeholderTextColor='#ccc'
        />

        <Text style={styles.title}>새로운 비밀번호</Text>
        <TextInput
          style={styles.input}
          placeholder="비밀번호"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          placeholderTextColor='#ccc'
        />

        <Text style={styles.title}> 비밀번호 확인</Text>
        <TextInput
          style={styles.input}
          placeholder="비밀번호"
          value={repassword}
          onChangeText={setrePassword}
          secureTextEntry
          placeholderTextColor='#ccc'
        />
        <Text style={styles.title}>코드</Text>
        <View style={styles.codeview}>
          <TextInput 
            style={styles.inputcode}
            placeholder="코드" 
            value={code} 
            onChangeText={setCode}
            placeholderTextColor='#ccc'
          />
        
          <TouchableOpacity onPress={getCode} style={styles.sendcode}>
          <Text style={styles.buttonText}>코드 발송</Text>
          </TouchableOpacity>
        </View>

        <TouchableOpacity onPress={handleOnChangePassword} style={styles.button}>
          <Text style={styles.buttonText}>비밀번호 변경</Text>
        </TouchableOpacity>

      </Animatable.View>
    </View>
  );
}
