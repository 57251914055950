import { createSlice } from '@reduxjs/toolkit';

type Props = {
  userinfo : {};
  email : string,
  user_id : string;
  token : string;
  isLoading: boolean;
  isLogin: boolean;
  timer : number;
  errorMessage: string;
  coinLists : []
};

const initialState: Props = {
  userinfo : {},
  email : '',
  user_id : '',
  token : '',
  isLoading: false,
  isLogin: false,
  timer : 0,
  errorMessage: '',
  coinLists : [],
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    LOGIN_USER: state => ({ ...state, isLoading: true, isLogin: false  }),
    LOGIN_USER_SUCCESS: (state, { payload: { token } }) => ({
      ...state,
      token : token,
      isLoading: false,
      isLogin: true,
      errorMessage: 'loginSuccess'
    }),
    LOGIN_USER_FAIL: (state, { payload: { errorMessage } }) => ({
      ...state,
      isLoading: false,
      errorMessage,
    }),
    REGISTER_USER: (state, { payload: { } }) => ({
      ...state,
      isLoading: true,
      isLogin: false,
      errorMessage: '',
    }),
    REGISTER_USER_SUCCESS: (state, { }) => ({
      ...state,
      isLoading: false,
      isLogin: true,
      errorMessage: 'registerSuccess',
    }),
    REGISTER_USER_FAIL: (state, { payload: { errorMessage } }) => ({
      ...state,
      isLoading: false,
      isLogin: false,
      errorMessage : errorMessage,
    }),
    LOGIN_CHECK: (state, {} ) => ({
      ...state, isLoading: true, isLogin: false
    }),
    LOGIN_CHECK_SUCCESS: (state, { payload: { email, userinfo, user_id, coinLists } }) => ({
      ...state,
      isLoading: false,
      isLogin: true,
      email : email,
      userinfo : userinfo,
      user_id : user_id,
      coinLists : coinLists,
      errorMessage: 'loginCheckSuccess',
    }),
    LOGOUT_USER: (state, {}) => ({
      ...state,
      email : '',
      userinfo: {},
      token : '',
      isLoading: false,
      isLogin: false,
      errorMessage : 'logOutSuccess',
    }),
  },
});

const { actions, reducer } = AuthSlice;

export const {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOGOUT_USER,
  LOGIN_CHECK,
  LOGIN_CHECK_SUCCESS,
  
} = actions;

export default reducer;
