import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';

import spacings from '../../theme/spacings';
import { StyleSheet } from 'react-native';
import { COLORS } from '../../theme/animateColors';

export const Container = styled.View`
  flex: 1;
  background-color: #fff;
`;

export const Title = styled.Text`
  font-size: 16px;
  text-align: center;
  font-family: ${useFontFamily(400)};
`;

export const FlatList = styled.FlatList.attrs({
  contentContainerStyle: {
    paddingVertical: spacings.SMALL,
  },
})`
  background-color: #fff;
`;

export const StyledEmptyListContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.Image`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const StyledEmptyList = styled.View`
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
`;

export const StyledEmptyListView = styled.View`
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
`;

export const StyledEmptyListText = styled.Text`
  font-size: 16px;
  color: #000;
  font-family: ${useFontFamily(500)};
`;

export const LeftSide = styled.View`
  width: 70px;
  justify-content: center;
  align-items: center;
`;

export const RightSide = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #cdcdcd;
`;

export const ViewIcon = styled.View`
  width: 75px;
  height: 75px;
  justify-content: center;
  align-items: center;
`;

export const ImgIcon = styled.Image`
  width: 50px;
  height: 50px;
`;

export const CardContainer = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
`;

export const Name = styled.Text`
  width: 40%;
  font-size: 14px;
  text-align: left;
  font-family: ${useFontFamily(500)};
`;

export const Price = styled.Text`
  width: 60%;
  font-size: 14px;
  text-align: right;
  padding-right: 30px;
  font-family: ${useFontFamily(500)};
`;

export const Percent = styled.Text`
  width: 100px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: green;
  font-family: ${useFontFamily(100)};
`;

export const PriceUsd = styled.Text`
  width: 30%;
  font-size: 14px;
  text-align: left;
  padding-right: 30px;
  color: #808080;
  font-family: ${useFontFamily(500)};
`;

export const RightSideViewHeader = styled.View`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const RightSideViewBottom = styled.View`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const PercentChange24h = styled.Text`
  width: 70%;
  font-size: 14px;
  text-align: left;
  color: green;
  font-family: ${useFontFamily(400)};
`;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  containerHeader: {
    marginTop: '3%',
    marginBottom: '3%',
    paddingStart: '5%',
    paddingEnd: '5%',
    flexDirection: 'row', 
    justifyContent: 'space-between',
  },
  message: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign : 'center',
    color: COLORS.FORM,
  },

  containerForm: {
    flex: 1,
    backgroundColor: COLORS.FORM,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingStart: '5%',
    paddingEnd: '5%',
  },
  title: {
    fontSize: 20,
    marginTop: 28,
  },
  input: {
    width: '100%',
    height: 40,
    borderBottomWidth: 1,
    fontSize: 16,
    marginBottom: 16,
  },
  textarea: {
    width: '100%',
    backgroundColor : '#eee',
    borderBottomWidth: 1,
    fontSize: 16,
    marginBottom: 16,
    flexShrink : 1,
    height : 100,
  },

  button: {
    width: '100%',
    borderRadius: 8,
    marginTop : 50,
    backgroundColor: COLORS.PRIMARY_COLOR,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: COLORS.FORM,
  },
  buttonDisable : {
    width: '100%',
    borderRadius: 8,
    marginTop : 50,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor : 'gray',
  },
  buttonRegister: {
    marginTop: 14,
    alignSelf: 'center',
  },
  registerText: {
    color: COLORS.SUB_TITLE,
  },
  fees : {
    fontSize: 20,
  },
  containerText: {
    marginTop: '1%',
    marginBottom: '1%',
    paddingStart: '5%',
    paddingEnd: '5%',
    flexDirection: 'row', 
    justifyContent: 'space-between',
  },
  scrolltext: {
    color : 'black',
  },
  scrolltext1: {
    fontWeight : "900",
    textAlign :"center",

  },
  btn_agree_not : {
    width: '48%',
    borderRadius: 8,
    marginTop : 50,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    color : 'white',
    backgroundColor: COLORS.DANGER_COLOR,
  },
  btn_agree_ok : {
    width: '48%',
    borderRadius: 8,
    marginTop : 50,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
    color : 'white',
    backgroundColor: COLORS.PRIMARY_COLOR,
  },
  scroll :{
    height : '100px',
  }

});
