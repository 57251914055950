import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient'
import axios from 'axios';

import { Notice, News } from '@screens/Notice';
// import { AuthProps } from '@screens/SignIn';
import moment from 'moment';

const { Navigator, Screen } = createMaterialTopTabNavigator();

import {
  ContainerHeader,
  Blank,
  AvailableBalance,
  AvailableBalanceTitle,
  ContainerVerticalButtons,
  RoundButton,
  ButtonTitle,
  ContainerVerticalTextButtons,
  Title,
  styles,
  SmallCard,
  Time,
  SmallContent,
} from './styles';
// import { useAuthContext } from '@contexts/AuthProvider';
import { saveUser } from '../../../src/constants/storage';

import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { ServerPath } from '../../constants/constants';

export function MaterialTopTab() {
  
  const { getItem } = useAsyncStorage(saveUser);
  const [gbitcoin, setGbit] = useState<string>({});

  const { navigate } = useNavigation();
  const [krw_rate, setKrwRate] = useState<Number>(1300);
  const [gbit_rate, setGbitRate] = useState<Number>(0);
  
  // 1달러 : 1248원
  // gbit 1 0.5달러
  // 1gibt  
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const auth = useSelector((state) => state.auth);
  
  useEffect(() => {
    if (auth) {
      const GBitCoin = auth.coinLists.find((n : any)=> {return n.token_type === 'GBIT'});
      if (GBitCoin !== undefined) setGbit(GBitCoin);
    }
    onInit();
    loadData().catch(console.error);
    
  }, []);

  async function loadData() {
    if (auth && auth !== '')  {
      setEmail(auth.email);
      setName(auth.userinfo.name);
    }
  }

  const onInit = async () => {

    const krw_rate_result = await (axios.get('https://api.bithumb.com/public/ticker/ALL'));
    if ( Object.keys(krw_rate_result.data).length !== 0 && krw_rate_result.data.length > 0) {
      setKrwRate(krw_rate_result.data.data['USDT'].opening_price);
    }

    const gbit_rate_result : any = await axios.get( ServerPath + '/api/ticker' );
    if (Object.keys(gbit_rate_result).length !== 0) {
      setGbitRate(Number(gbit_rate_result.data.result[0].last_price));
    }
  }


  function onWithdrawalNavigate() {
    navigate('Details');
  }

  function getCoinAmount(value : string) {
    return Number(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');;
  }
  
  const today = new Date();
  const purchase_price = 500;
  return (
    <>
      <ContainerHeader>
       <LinearGradient style={styles.containerCard} colors={['#3a7bd5', '#3a6073']}>
          <Title >보유 GBIT 총량</Title>
          <AvailableBalance>{Object.keys(gbitcoin).length !== 0? getCoinAmount(gbitcoin.total_amount): 0.00} GBIT</AvailableBalance>
          <AvailableBalanceTitle> (구매금액 :  {Object.keys(gbitcoin).length === 0 || krw_rate === 0 || gbit_rate ===0 || gbitcoin.total_amount === 0 ?"0":Math.floor(Number(gbitcoin.total_amount * 500)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원)</AvailableBalanceTitle>
          <TouchableOpacity onPress={onWithdrawalNavigate}>
            <ContainerVerticalButtons>
              <ContainerVerticalTextButtons>
                <RoundButton onPress={onWithdrawalNavigate}>
                  <ButtonTitle>  코인 교환하기 신청 </ButtonTitle>
                </RoundButton>
              </ContainerVerticalTextButtons>
            </ContainerVerticalButtons>
          </TouchableOpacity>
        </LinearGradient>
        <Blank/>
        <LinearGradient style={styles.containerCard} colors={['#3a7bd5', '#3a6073']}>
          <Title>TODAY</Title>
          <SmallCard>
            <SmallContent>
               <table>
                <tbody>
                  <tr>
                    <td style={styles.smalltitle}>
                      1 GBIT 원화시세
                    </td>
                    <td style={styles.smallcontent}>
                      { krw_rate === 0 || gbit_rate ===0 || gbitcoin.total_amount === 0 || isNaN(gbit_rate * krw_rate) ?"0":getCoinAmount(gbit_rate * krw_rate)} 원
                    </td>
                  </tr>
                </tbody>
               </table>
            </SmallContent>
          </SmallCard>
          <SmallCard>
            <SmallContent>
               <table>
               <tbody>
                  <tr>
                    <td style={styles.smalltitle}>
                      보유 GBIT 원화 가치
                    </td>
                    <td style={styles.smallcontent2}>
                      {Object.keys(gbitcoin).length === 0 || krw_rate === 0 || gbit_rate ===0 || gbitcoin.total_amount === 0 || isNaN(gbitcoin.total_amount * gbit_rate * krw_rate)  ?"0":Math.floor(Number(gbitcoin.total_amount * gbit_rate * krw_rate)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                    </td>
                  </tr>
                </tbody>
               </table>
            </SmallContent>
          </SmallCard>
          <SmallCard>
            <SmallContent>
               <table>
                <tbody>
                  <tr>
                    <td style={styles.smalltitle}>
                      현재 거래소 기준 평가액
                    </td>
                    <td style={gbit_rate * krw_rate > purchase_price? styles.smallplus : styles.smallminus}>
                    {gbit_rate * krw_rate > purchase_price?"+":""}
                    {Object.keys(gbitcoin).length === 0 || krw_rate === 0 || gbit_rate ===0 || gbitcoin.total_amount === 0 || isNaN(gbit_rate * krw_rate)  ?"0":getCoinAmount((gbit_rate * krw_rate) - purchase_price)/(purchase_price/100)} %
                    </td>
                  </tr>
                </tbody>
               </table>
            </SmallContent>
          </SmallCard>
        </LinearGradient>
        <Time>{moment(today).format('YYYY년 MM월 DD일 HH:mm:ss')} 기준으로 조회한 데이터입니다.</Time>
      </ContainerHeader>

      <Navigator
        screenOptions={{
          tabBarStyle: {
            backgroundColor: '#FFF',
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
          },
        }}
      >
        <Screen name="뉴스" component={News} />
        <Screen name="공지사항" component={Notice} />
      </Navigator>
    </>
  );
}
