import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity, Text } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { AssetsLogUrl } from '../../constants/constants';
import { saveUser } from '../../constants/storage';
import moment from 'moment';
import { FontAwesome } from '@expo/vector-icons';

import {
  Container,
  ContainerHeader,
  ContainerVerticalButtons,
  Button,
  FlatList,
  ViewIcon,
  LeftSide,
  RightSide,
  ContainerVerticalTextButtons,
  StyledEmptyList,
  StyledEmptyListView,
  StyledEmptyListText,
  CardContainer,
  Name,
  Price,
  RightSideViewHeader,
  RightSideViewBottom,
  PercentChange24h,
  styles,
  HalfCardContainer,
} from './styles';

import Loading from '@components/Loading';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export type CoinData = {
  id: string;
  symbol: string;
  name: string;
  rank: number;
  price_usd: number;
  price_btc: number;
  percent_change_24h: number;
};

export function Details() {
  const dispatch = useDispatch();
  const { navigate } = useNavigation();
  const [gbitcoin, setGbit] = useState<string>({});

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth) {
      const GBitCoin = auth.coinLists.find((n)=> {return n.token_type === 'GBIT'});
      if (GBitCoin !== undefined) setGbit(GBitCoin);
    }
    
    
  }, []);

   function getCoinAmount(value : string) {
    return Number(value).toFixed(2);
  }

  function onWithdrawalNavigate() {
    navigate('SwitchToPwr');
  }
  

  return (
    <Container>
    
      <>
        <ContainerHeader>
            <CardContainer>
              <Text style={styles.title}>총액</Text>
              <Text style={styles.amount}>{Object.keys(gbitcoin).length !== 0? getCoinAmount(gbitcoin.total_amount): 0.00} <Text style={styles.symbol}>GBIT</Text> </Text>
            </CardContainer>
            <CardContainer>
            <HalfCardContainer>
              <Text style={styles.title}>출금 가능</Text>
              <Text style={styles.amount}> {Object.keys(gbitcoin).length !== 0? getCoinAmount(gbitcoin.available_amount): 0.00} <Text style={styles.symbol}>GBIT</Text> </Text>
            </HalfCardContainer>
            <HalfCardContainer>
              <Text style={styles.title}>출금 대기</Text>
              <Text style={styles.amount}> {Object.keys(gbitcoin).length !== 0? getCoinAmount(gbitcoin.frozen_amount): 0.00} <Text style={styles.symbol}>GBIT</Text> </Text>
            </HalfCardContainer>
            </CardContainer>
          <CardContainer>
            <Text style={styles.title}>출금 불가</Text>
            <Text style={styles.amount}> {Object.keys(gbitcoin).length !== 0? getCoinAmount(gbitcoin.locked_amount): 0.00} <Text style={styles.symbol}>GBIT</Text> </Text>
          </CardContainer>
          <TouchableOpacity >
            <ContainerVerticalButtons>
              <ContainerVerticalTextButtons>
                <Button onPress={onWithdrawalNavigate}>
                    코인 교환하기 신청
                </Button>
              </ContainerVerticalTextButtons>
            </ContainerVerticalButtons>
          </TouchableOpacity>
        </ContainerHeader>
      </> 
      <AssetsLog />
    </Container>
  );
}

export function AssetsLog() {
  const { navigate } = useNavigation();

  const [isLoading, setLoading ] =  useState<boolean>(true);
  const [assetsLog, setAssetsLog] = useState<[]>([]);

  useEffect(() => {
    getAssetsLog();
    
  }, []);

  const getAssetsLog = () => {
    async function fetchData() {
      const { getItem } = useAsyncStorage(saveUser);
      const user = await getItem();
      
      if (!user || Object.keys(user).length === 0 ) {
        navigate("WelCome");
        return;
      }
      const senddata ={ search: '', token_type : '',selected: 1, perPage: 20 }
      axios
        .post( AssetsLogUrl , senddata , { headers: { Authorization: `Bearer ${user}` } } )
        .then((data) => {
          if (data.data.count >=0){
            setAssetsLog(data.data.select);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      }
    fetchData();
    }

  function renderEmptyList() {
    if (isLoading && !listCoinsData.length) return;

    return (
      <>
        <StyledEmptyList>
          <StyledEmptyListView>
            <StyledEmptyListText>There is no data!</StyledEmptyListText>
          </StyledEmptyListView>
        </StyledEmptyList>
      </>
    );
  }

  function renderItem({ item }: CoinData) {
    return (
      <CardContainer activeOpacity={0.8} onPress={() => navigate('DetailCoin', { id: item?.id })}>
        <LeftSide>

          <ViewIcon style={{backgroundColor : Number(item.amount) >= 0?"#0275d8":"#d9534f"}}>
            <FontAwesome 
            name={Number(item.amount) >= 0?"arrow-down":"arrow-up"} 
            size={25} color={Number(item.amount) >= 0?"white":"white"} 
            />
          </ViewIcon>
        </LeftSide>
        <RightSide>
          <RightSideViewHeader>
            <Name>{item.token_type}</Name>
            <Price>
              Amount : {Number(item?.amount).toFixed(2)}
            </Price>
          </RightSideViewHeader>
          <RightSideViewBottom>
            <PercentChange24h>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</PercentChange24h>
          </RightSideViewBottom>
        </RightSide>
      </CardContainer>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <FlatList
          data={assetsLog}
          keyExtractor={(item: any) => item.id}
          ListEmptyComponent={renderEmptyList}
          renderItem={renderItem}
        />
      )}
    </>
  );
}