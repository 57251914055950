import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AuthProps } from '@screens/SignIn';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { useSelector, useDispatch } from 'react-redux';
import { saveUser } from '../constants/storage';
import { LOGIN_USER, REGISTER_USER } from '@store/slices/AuthSlice';
import alert from '../helpers/functions/alert'


const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

function AuthProvider({ children, props }: any) {

  const state = useSelector((state) => state);
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  useEffect(() => {

  });

  function signIn(email: string, password: string) {
    // console.log(`Email: ${email} / Pass: ${password}`);

    if (email === '' && password === '') return;

    if (email !== '' && password !== '') {
      const user = {email : email, password : password, navigate : navigate}
      dispatch(LOGIN_USER(user));
    }
  }

  function signUp(email: string, password: string, recommend_code: string, name : string, hpNumber:string) {

    if (email === '' && password === '' && recommend_code ==='') return;

    if (email !== '' && password !== '' && recommend_code !== '') {
      const user = {email : email, password : password, recommend:recommend_code, name : name, hpNumber: hpNumber, navigate : navigate}
      dispatch(REGISTER_USER(user));
    }
  }

  
  return <AuthContext.Provider value={{ signIn, signUp  }}>{children}</AuthContext.Provider>;
}


export default AuthProvider;