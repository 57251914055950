import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';

import { styles } from './styles';
import { useAuthContext } from '@contexts/AuthProvider';

export type AuthProps = {
  email: string;
  status: boolean;
  logged: boolean;
};

type Props = {
  signIn: (email: string, password: string) => void;
};

export function SignIn({ signIn }: Props) {
  
  const { navigate } = useNavigation();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  function handleOnLogin() {
    signIn(email, password);
  }

  return (
    <View style={styles.container}>
      <Animatable.View animation="fadeInLeft" delay={600} style={styles.containerHeader}>
        <Text style={styles.message}>Welcome</Text>
      </Animatable.View>

      <Animatable.View style={styles.containerForm} animation="fadeInDown">
        <Text style={styles.title}> 아이디(이메일)</Text>
        <TextInput 
          style={styles.input} 
          placeholder="이메일" 
          value={email} 
          onChangeText={setEmail}
          placeholderTextColor='#ccc' 
          />

        <Text style={styles.title}>비밀번호</Text>
        <TextInput
          style={styles.input}
          placeholder="비밀번호"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          placeholderTextColor='#ccc'
        />

        <TouchableOpacity onPress={handleOnLogin} style={styles.button}>
          <Text style={styles.buttonText}>로그인</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.buttonRegister} onPress={() => {navigate('ForgotPassword')} }>
          <Text style={styles.registerText}>Forgot Password?</Text>
        </TouchableOpacity>
      </Animatable.View>
    </View>
  );
}

export default function (props: any) {
  const { signIn } = useAuthContext();

  return <SignIn signIn={signIn} {...props} />;
}
