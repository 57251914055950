import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';
import useFontFamily from '@hooks/useFontFamily';
import { COLORS } from '../../theme/animateColors';

export const Container = styled.View`
  flex: 1;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: 18px;
  text-align: center;
  font-family: ${useFontFamily(400)};
`;


export const styles = StyleSheet.create({
  flexVertical: {
    flexDirection: 'column',
    marginTop: 10
  },

  flexHorizontal: {
    flexDirection: 'row',
    marginTop: 25,
    marginBottom: 8,
  },

  icon: {
    marginTop: 1,
  },

  textInp: {
    backgroundColor: '#434647',
    color: '#fff',
    height: 40,
    fontSize: 15,
    paddingHorizontal: 15,
    borderRadius: 5,
  },

  button: {
    width: '100%',
    borderRadius: 8,
    marginTop : 30,
    backgroundColor: COLORS.PRIMARY_COLOR,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonLogOut: {
    width: '100%',
    borderRadius: 8,
    marginTop : 20,
    backgroundColor: COLORS.DANGER_COLOR,
    paddingVertical: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom : 8,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },

  title: {
    color: '#434647',
    marginLeft:5,
    textAlignVertical:'center',
    fontSize: 16,
    marginTop:10
  },

  avatar: {
    alignItems:'center', 
    marginTop:10
  },

  photoLabel: {
    marginTop:15,
    fontSize:15,
    fontWeight:'500',
    textAlignVertical:'center'
  },
});

