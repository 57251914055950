import React from 'react';
import { Platform } from 'react-native';

import { useTheme } from 'styled-components/native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { FontAwesome } from '@expo/vector-icons';

const { Navigator, Screen } = createBottomTabNavigator();

import { MaterialTopTab } from '../tabs/MaterialTopTab';
import { NoticeTopTab } from '../tabs/NoticeTopTab';

import { Details } from '@screens/Detail';
import { Profile } from '@screens/Profile';

import { CustomTabButton } from '@components/CustomTabButton';

export function BottomTab() {
  const COLORS = useTheme();

  return (
    <Navigator
      initialRouteName ="Home"
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: COLORS.PRIMARY_COLOR,
        tabBarInactiveTintColor: 'gray',
        tabBarStyle: {
          paddingVertical: Platform.OS === 'ios' ? 0 : 0,
        },
      }}
    >
      <Screen
        name="입출금 내역"
        component={Details}
        options={{
          tabBarIcon: ({ focused, color }) => <FontAwesome name="newspaper-o" size={25} color={color} />,
        }}
      />
      
      <Screen
        name="Home"
        component={MaterialTopTab}
        options={{
          title: '',
          tabBarIcon: ({ focused, color }) => <CustomTabButton name="home" size={25} color={color} />,
        }}
      />

      <Screen
        name="내 정보"
        component={Profile}
        options={{
          tabBarIcon: ({ focused, color }) => <FontAwesome name="user" size={25} color={color} />,
        }}
      />
    </Navigator>
  );
}
