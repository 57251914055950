import React, { useEffect, useState } from 'react';

import Hyperlink from 'react-native-hyperlink'
import { Linking } from 'react-native';

import { useRoute } from '@react-navigation/native';
import axios from 'axios';
import moment from 'moment';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { ServerPath } from '../../constants/constants';
import { saveUser } from '../../../src/constants/storage';

import { Container, UpSide, ImgIcon, DateText, Title, Content, TitleLabel, styles } from './styles';
import {  } from '@screens/Board/styles';

export function Board() {
  
  const [ item, setItem ] = useState({});
  const {
    params: { id, type },
  } = useRoute();
  
  useEffect(() => {
    Init();

  }, []);
  
  const Init = () =>{
    async function fetchData() {
      const { getItem } = useAsyncStorage(saveUser);
      const user = await getItem();
      var senddata = {};
      if (type==='news')
        senddata = {newsId: id}
      else
        senddata = {noticeId : id}

      let url;
      type==='news'? url = '/news/detail': url = '/notice/detail';

      axios
        .post(ServerPath + url , senddata, { headers: { Authorization: `Bearer ${user}` } } )
        .then((data) => {
          setItem(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      }
    fetchData();
  }


  return (
    <Container>
       
        <DateText> {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</DateText>

        {type ==='news'?
        <UpSide> 
        <ImgIcon source={item.image_kor} resizeMode="center" />
        </UpSide>
        :''
        }

        <Title>
          {item.title_kor}
        </Title>
        
        <Hyperlink linkStyle={styles.hyperlinkStyle} onPress={(url) => Linking.openURL(url)}>
          <Content>
            {item.content_kor}
          </Content>
        </Hyperlink>
          
        
    </Container>
  );
}
