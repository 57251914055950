import { Alert, Platform, AlertButton, AlertOptions } from 'react-native'


const alertPolyfill = (title: string, message?: string, buttons?: AlertButton[], options?: AlertOptions) => {
    const result = window.alert([title, message].filter(Boolean).join('\n'))

}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

export function fAlert(message) {
    alert(message);
}

export default function alertMessage (message : string) {

    switch(message) {
        case 'invalid recommend'  : alert('가입 에러','초대코드가 잘못되었습니다.'); break;
        case 'invalid'            : alert('로그인 오류','ID나 암호가 잘못되었습니다.'); break;
        case 'duplicate'          : alert('가입 에러','중복된 이메일입니다.'); break;
        case 'login_fail'          : alert('로그인 오류','로그인할 수 없습니다.'); break;
        default : break;
      }


}