import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useSelector, useDispatch } from 'react-redux';

import { LOADING_COINS } from '@store/slices/CoinSlice';
import moment from 'moment';
import axios from 'axios';

import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { ServerPath } from '../../constants/constants';
import { saveUser } from '../../../src/constants/storage';

import {
  Container,
  NoticeContainer,
  StyledEmptyList,
  StyledEmptyListView,
  StyledEmptyListText,
  FlatList,
  ImgIcon,
  CardContainer,
  UpSide,
  NoticeTitle,
  Title,
  Content,
  NoticeContent
} from './styles';

import Lottie from 'lottie-react-native';
import Loading from '@components/Loading';

import empty from '@assets/animations/empty-list';
import icDefault from '@assets/icons/default.png';
import { coinImage, formatMoney } from '@helpers/functions/utils/';

export type CoinData = {
  id: string;
  symbol: string;
  name: string;
  rank: number;
  price_usd: number;
  price_btc: number;
  percent_change_24h: number;
};


function renderEmptyList() {
  return (
    <>
      <StyledEmptyList>
        <Lottie source={empty} loop autoPlay resizeMode="contain" />
        <StyledEmptyListView>
          <StyledEmptyListText>There is no data!</StyledEmptyListText>
        </StyledEmptyListView>
      </StyledEmptyList>
    </>
  );
}

export function Notice() {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const [ isLoading, setLoading ] = useState(true);
  const [ items, setItems ] = useState([]);
  
    
  useEffect( () => {
    Init();
  }, []);

  const Init = () =>{
    async function fetchData() {
      const { getItem } = useAsyncStorage(saveUser);
      const user = await getItem();
      const senddata = {page : 0, unit : 10}
      axios
        .post(ServerPath + '/notice/list' , senddata, { headers: { Authorization: `Bearer ${user}` } } )
        .then((data) => {
          if (data.data.count >=0) {
            setItems(data.data.select);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      }
    fetchData();
  }

  useEffect(() => {
    dispatch(LOADING_COINS());
  }, []);

  function renderItem({ item }: CoinData) {
    return (
      <NoticeContainer onPress={() => navigate('Detail', { id: item?.id, type:'notice' })}>
        <NoticeTitle>{item.title_kor}</NoticeTitle>
        <NoticeContent>{item.content_kor}</NoticeContent>
      </NoticeContainer>
    );
  } 
  
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <FlatList
          data={items}
          ListEmptyComponent={renderEmptyList}
          renderItem={renderItem}
        />
      )}
    </Container>
  );
}

export function News() {
  const { navigate } = useNavigation();
  const dispatch = useDispatch();
  const [ isLoading, setLoading ] = useState(true);
  const [ items, setItems ] = useState([]);
  
    
  useEffect( () => {
    Init();
  }, []);

  const Init = () =>{
    async function fetchData() {
      const { getItem } = useAsyncStorage(saveUser);
      const user = await getItem();
      const senddata = {page : 0, unit : 10}
      axios
        .post(ServerPath + '/news/list' , senddata, { headers: { Authorization: `Bearer ${user}` } } )
        .then((data) => {
          if (data.data.count >=0) {
            setItems(data.data.select);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      }
    fetchData();
  }

  useEffect(() => {
    dispatch(LOADING_COINS());
  }, []);

  function renderItem({ item}: CoinData) {
    return (
      <CardContainer onPress={() => navigate('Detail', { id: item?.id, type : 'news' })}>
        <UpSide> 
        <ImgIcon source={item.image_kor} resizeMode="object-fit" />
        </UpSide>
        <Title>{item.title_kor}</Title>
        <Content>{item.content_kor}</Content>
      </CardContainer>
    );
  } 
  
  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <FlatList
          data={items}
          ListEmptyComponent={renderEmptyList}
          renderItem={renderItem}
        />
      )}
    </Container>
  );
}

