import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  View,
  Text,
  TouchableOpacity,
  TextInput,

} from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from './styles';
import { useAuthContext } from '@contexts/AuthProvider';
import { fAlert } from '../../helpers/functions/alert'

export type AuthProps = {
  email: string;
  status: boolean;
  logged: boolean;
};

type Props = {
  signUp: (email: string, password: string, recommend_code: string ) => void;
  user: AuthProps;
};

export function SignUp({ signUp, user }: Props) {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hpNumber, setHpNumber] = useState<string>('');
  const [recommend_code, setRecommend_code] = useState<string>('');
  const { navigate } = useNavigation();

  function handleOnRegister() {
    if (validateEmail(email) !== '')
    {
      fAlert('email이 형식에 맞지 않습니다.')
      return;
    }
    signUp(email, password, recommend_code, name, hpNumber);
  }
  function handleCancel() {
    navigate('WelCome');
  }

  const validateEmail = (value : string) => {
    let error = '';
    if (!value) {
      error = 'Please enter your email address';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;
  };


  return (
    <View style={styles.container}>
      <Animatable.View animation="fadeInLeft" delay={600} style={styles.containerHeader}>
        <Text style={styles.message}>Welcome</Text>
      </Animatable.View>

      <Animatable.View style={styles.containerForm} animation="fadeInDown">
        <Text style={styles.title}>사용자 이름</Text>
        <TextInput 
          style={styles.input}
          placeholder="이름" 
          value={name} 
          onChangeText={setName}
          placeholderTextColor='#ccc'
        />

        <Text style={styles.title}>아이디(이메일)</Text>
        <TextInput 
          style={styles.input}
          placeholder="아이디" 
          value={email} 
          onChangeText={setEmail}
          placeholderTextColor='#ccc'
        />

        <Text style={styles.title}>비밀번호</Text>
        <TextInput
          style={styles.input}
          placeholder="비밀번호"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          placeholderTextColor='#ccc'
        />

        <Text style={styles.title}>핸드폰 번호</Text>
        <TextInput 
          style={styles.input}
          placeholder="핸드폰 번호" 
          value={hpNumber} 
          onChangeText={setHpNumber}
          placeholderTextColor='#ccc'
        />
        <Text style={styles.title}>스마트 코드</Text>
        <TextInput
          style={styles.input}
          placeholder="스마트 코드"
          value={recommend_code}
          onChangeText={setRecommend_code}
          placeholderTextColor='#ccc'
        />

        <TouchableOpacity onPress={handleOnRegister} style={styles.button}>
          <Text style={styles.buttonText}>가입</Text>
        </TouchableOpacity>

      </Animatable.View>
    </View>
  );
}


export default function (props: any) {
  const { signUp, user } = useAuthContext();

  return <SignUp signUp={signUp} user={user} {...props} />;
}

