export const GREEN = '#2DB572';
export const RED = '#EE225D';
export const LIGHTGREY = '#ADB7C3'
export const LIGHTBLACK = '#4E5B69'

//export const ServerPath = 'http://gbit.shop:5412';
export const ServerPath = 'http://feelinglucky.shop:5412';
//export const ServerPath = 'http://127.0.0.1:5412';
export const loginUrl = `${ServerPath}/login`;
export const RegisterUrl = `${ServerPath}/register`;
export const UserInfoUrl = `${ServerPath}/user/info`;
export const AssetsLogUrl = `${ServerPath}/user/asset_log/list`;

